import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled, Typography } from '@mui/material';

import { DasIdWillAssign, InputValidationError } from '../../../../types';
import { AssignDeviceItemData } from '../../../../types/Device';
import { Plant } from '../../../../types/Resource';

import { getProjectPlants } from '../../../../apis/DastrackApi';
import { useAppSelector } from '../../../../hooks';
import useAssignedTargetMap from '../../../../hooks/useAssignedTargetMap';

import SelectBindToDevice from '../../../../components/SelectBindToDevice';

import DeleteSvgIcon from '../../../../assets/SvgIcon/DeleteSvgIcon';

const Container = styled('div')`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  height: 134px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Content = styled('div')`
  border-radius: 4px;
  padding: 10px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
  padding-left: 10px;
`;

const ContainerDelete = styled('div')`
  height: 32px;
  width: 32px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const TitleDevice = styled('div')(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

interface ConnectedDeviceProps {
  connectedDasId?: DasIdWillAssign;
  onChange: (index: number, datas: DasIdWillAssign) => void;
  index: number;
  onDeleteCard: (index: number) => void;
}

const ConnectedDeviceCard: React.VFC<ConnectedDeviceProps> = ({
  connectedDasId,
  index,
  onDeleteCard,
  onChange,
}) => {
  const { t } = useTranslation('project-setting');
  const { projectId } = useParams();
  const { dastracks } = useAppSelector((store) => store.plantManagement);
  const [dasID, setDasID] = useState<DasIdWillAssign | undefined>();
  const [dasIDError, setDasIDError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });

  useEffect(() => {
    setDasID(connectedDasId);
  }, [connectedDasId]);

  const { data: plantMap, isLoading } = useAssignedTargetMap<Plant>(
    ['plant-map', projectId],
    projectId,
    getProjectPlants,
    {
      enabled: !!projectId,
      refetchInterval: parseInt(
        import.meta.env.VITE_BASIC_RESCAN_TIME ?? '150000',
      ),
      refetchOnWindowFocus: false,
    },
  );

  const LIST_DASTRACKS = useMemo(() => {
    if (dastracks.length > 0) {
      return dastracks.map<AssignDeviceItemData>((dastrack) => ({
        id: dastrack.id,
        dasId: dastrack.dasId,
        name: dastrack.dasId,
        batteryLevel: dastrack.shadow.dataPoint.batteryLevel.value
          ? dastrack.shadow.dataPoint.batteryLevel.value
          : 0,
        bindTo: plantMap?.[dastrack.plantId ?? '']?.name ?? '',
        isAssigned: !!dastrack.plantId,
        deviceType: 'dastrack',
      }));
    }
    return [];
  }, [dastracks, plantMap]);

  useEffect(() => {
    if (dasID?.name) {
      onChange(index, dasID);
    }
  }, [dasID]);

  return (
    <Container>
      <Header>
        <Typography variant="body2">
          {t(
            'page.plant-management.dialog.create-plant.connected-device.title-card',
            {
              count: index + 1,
            },
          )}
        </Typography>
        <ContainerDelete
          onClick={() => {
            onDeleteCard(index);
          }}
        >
          <DeleteSvgIcon />
        </ContainerDelete>
      </Header>
      <Content>
        <TitleDevice>
          DasTrack
          <SpanRequire>*</SpanRequire>
        </TitleDevice>
        <SelectBindToDevice
          isLoading={isLoading}
          data={LIST_DASTRACKS}
          placeholder={t(
            'page.plant-management.dialog.create-plant.connected-device.choose-device',
            { deviceType: 'DasTrack' },
          )}
          popperPlaceholder="Das ID"
          onSelect={(d) => {
            setDasID(d);
          }}
          onBlur={() => {
            if (!dasID) {
              setDasIDError({
                isError: true,
                message: t(
                  'page.plant-management.dialog.create-plant.connected-device.select-device',
                ),
              });
            } else {
              setDasIDError({
                isError: false,
                message: '',
              });
            }
          }}
          selectedId={dasID?.id}
          error={dasIDError}
          type="dastrack"
        />
      </Content>
    </Container>
  );
};

export default ConnectedDeviceCard;
