import {
  BasicResponse,
  DeviceType,
  PagedResponse,
  Track,
  WorkerCertificate,
  WorkerGroup,
} from '../types';
import { Dasloop } from '../types/Device';
import { CreateWorkerBasicInformation, Worker } from '../types/Resource';

import { delay } from '../utils/common';

import DsmAxios from './DsmAxios';

export const getUserDasloops = async (params?: {
  dasId?: string;
  id?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  /**
   * limit too large will cause server response timeout
   */
  let limit = params?.limit ?? 100;
  if (import.meta.env.MODE === 'development') {
    limit = params?.limit ?? 100;
  }
  await delay(100);
  return DsmAxios.get<PagedResponse<Dasloop>>(`v1/dasloops`, {
    params: {
      ...params,
      limit,
    },
  });
};

export const getProjectDasloops = async (data: {
  projectId: string;
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    id?: string;
    dasId?: string;
    assignedWorker?: boolean;
    orderBy?: 'das_id' | 'last_data_point_received_at';
    limit?: number;
  };
}) => {
  /**
   * limit too large will cause server response timeout
   */
  let limit = data.params?.limit ?? 100;
  if (import.meta.env.MODE === 'development') {
    limit = data.params?.limit ?? 100;
  }
  await delay(100);
  return DsmAxios.get<PagedResponse<Dasloop>>(
    `v1/projects/${data.projectId}/dasloops`,
    {
      params: { ...data.params, limit },
    },
  );
};

export const getDasloopByDasId = (data: { dasId: string }) => {
  return DsmAxios.get<BasicResponse<Dasloop>>(`v1/dasloops/${data.dasId}`);
};

export const createWorkerGroup = ({
  projectId,
  name,
  remark,
}: {
  projectId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/worker-groups`, {
    name,
    remark,
  });
};

export const getGroupOfWorkers = ({
  projectId,
  groupId,
  params,
}: {
  projectId: string;
  groupId: string;
  params?: {
    name?: string;
    dasId?: string;
    limit?: number;
    nextCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Worker>>(
    `v1/projects/${projectId}/worker-groups/${groupId}/workers`,
    {
      params,
    },
  );
};

export const updateWorkerGroup = ({
  projectId,
  groupId,
  name,
  remark,
  sequence,
}: {
  projectId: string;
  groupId: string;
  name?: string;
  remark?: string;
  sequence?: number | null;
}) => {
  return DsmAxios.patch<WorkerGroup>(
    `v1/projects/${projectId}/worker-groups/${groupId}`,
    { name, remark, sequence },
  );
};

export const createWorker = (data: {
  projectId: string;
  payload: CreateWorkerBasicInformation;
}) => {
  return DsmAxios.post<{ data: Worker }>(
    `v1/projects/${data.projectId}/workers`,
    data.payload,
  );
};

export const updateWorker = (data: {
  projectId: string;
  workerId: string;
  payload: {
    orgId: string;
    name: string;
    gender?: 'male' | 'female';
    birthday?: string;
    imageURL?: string | null;
    email?: string;
    phone?: string | null;
    division?: string;
    trade?: string;
    registrationNumber?: string | null;
    remark?: string;
    subContractorName?: string;
  };
}) => {
  return DsmAxios.patch(
    `v1/projects/${data.projectId}/workers/${data.workerId}`,
    data.payload,
  );
};

export const updateWorkerGroupIds = ({
  projectId,
  workerId,
  groupIds,
}: {
  projectId: string;
  workerId: string;
  groupIds: string[];
}) => {
  return DsmAxios.put(`v1/projects/${projectId}/workers/${workerId}/groups`, {
    groupIds,
  });
};

export const uploadWorkerAvatar = ({
  projectId,
  workerId,
  imageFile,
}: {
  projectId: string;
  workerId: string;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post<any>(
    `v1/projects/${projectId}/workers/${workerId}/avatar`,
    formData,
  );
};

interface WorkerCertificateParam {
  projectId: string;
  workerId: string;
  name: string;
  number: string;
  expiryDate: string;
}

export const createWorkerCertificate = ({
  projectId,
  workerId,
  name,
  number,
  expiryDate,
}: WorkerCertificateParam) => {
  return DsmAxios.post<BasicResponse<WorkerCertificate>>(
    `v1/projects/${projectId}/workers/${workerId}/certificates`,
    {
      name,
      number,
      expiryDate,
    },
  );
};

export const updateWorkerCertificate = ({
  projectId,
  workerId,
  certificateId,
  name,
  number,
  expiryDate,
}: {
  projectId: string;
  workerId: string;
  certificateId: string;
  name?: string;
  number?: string;
  expiryDate?: string;
}) => {
  return DsmAxios.patch(
    `v1/projects/${projectId}/workers/${workerId}/certificates/${certificateId}`,
    {
      name,
      number,
      expiryDate,
    },
  );
};

interface AvatarCertificateParams {
  projectId: string;
  workerId: string;
  certificateId: string;
  imageFile: File;
}

export const uploadAvatarCertificate = ({
  projectId,
  workerId,
  certificateId,
  imageFile,
}: AvatarCertificateParams) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post<any>(
    `v1/projects/${projectId}/workers/${workerId}/certificates/${certificateId}/avatar`,
    formData,
  );
};

export const bindDasloopToWorker = ({
  projectId,
  dasId,
  workerId,
}: {
  projectId: string;
  dasId: string;
  workerId: string;
}) => {
  return DsmAxios.put(`v1/projects/${projectId}/dasloops/${dasId}/worker`, {
    workerId,
  });
};

export const unbindDasloop = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete(`v1/projects/${projectId}/dasloops/${dasId}/worker`);
};

export const getProjectWorkers = async (data: {
  projectId: string;
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    id?: string;
    registrationNumber?: string;
  };
}) => {
  const limit = data.params?.limit ?? 200;
  await delay(100);
  return DsmAxios.get<PagedResponse<Worker>>(
    `v1/projects/${data.projectId}/workers`,
    {
      params: { ...data.params, limit },
    },
  );
};

export const getWorkerCertificate = ({
  projectId,
  workerId,
}: {
  projectId: string;
  workerId: string;
}) => {
  return DsmAxios.get<BasicResponse<Array<WorkerCertificate>>>(
    `v1/projects/${projectId}/workers/${workerId}/certificates`,
  );
};

export const setGroupWorkers = ({
  projectId,
  groupId,
  workerIds,
}: {
  projectId: string;
  groupId: string;
  workerIds: Array<string>;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/worker-groups/${groupId}/workers`,
    {
      workerIds,
    },
  );
};

export const getWorkerGroups = (
  projectId: string,
  params?: {
    id?: string;
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    orderBy?: 'sequence';
    order?: 'asc' | 'desc';
  },
) => {
  return DsmAxios.get<PagedResponse<WorkerGroup>>(
    `v1/projects/${projectId}/worker-groups`,
    { params },
  );
};

export const getWorkerTracks = (
  projectId: string,
  workerId: string,
  params: {
    fromDate: string;
    toDate: string;
    limit?: number;
    order: 'desc' | 'asc';
    nextCursor?: string;
    endpointType?: DeviceType;
  },
) => {
  return DsmAxios.get<PagedResponse<Track>>(
    `v1/projects/${projectId}/workers/${workerId}/tracks`,
    {
      params,
    },
  );
};

export const getAllWorkersTrack = (
  projectId: string,
  params: {
    fromDate: string;
    toDate: string;
    limit?: number;
    order: 'desc' | 'asc';
    nextCursor?: string;
    endpointType?: DeviceType;
  },
) => {
  return DsmAxios.get<PagedResponse<Track>>(
    `v1/projects/${projectId}/workers/all/tracks`,
    {
      params,
    },
  );
};

type GetProjectDasloopCountPayload = {
  projectId: string;
};

export const getProjectDasloopCount = ({
  projectId,
}: GetProjectDasloopCountPayload) => {
  return DsmAxios.get<BasicResponse<{ [dasId: string]: boolean | undefined }>>(
    `v1/projects/${projectId}/dasloops/count`,
  );
};
