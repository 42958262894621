import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AOA,
  AoATag,
  AOAUwb,
  Beacon,
  CCTV,
  DataDevice,
  SelectedDevice,
} from '../../../types';
import {
  Dasair,
  Dasloop,
  Daspower,
  Dastemp,
  Dastrack,
  DastrackV,
} from '../../../types/Device';

type MyDeviceListState = {
  projectSelected: string[];
  dataDeviceSelected: DataDevice[];
  deviceSelected: SelectedDevice;
  projectWillAssign: {
    id: string;
    name: string;
  };
  isSuccessToAssigned: boolean;
  dastracks: Dastrack[];
  dasloops: Dasloop[];
  daspowers: Daspower[];
  dastemps: Dastemp[];
  cctvItem: CCTV | undefined;
  isSuccessToEdit: boolean;
  aoaItem: AOA | undefined;
  aoaUwbItem: AOAUwb | undefined;
  aoaTagItem: AoATag | undefined;
  dastrackVItem: DastrackV | null;
  dasairItem: Dasair | undefined;
  beaconItem: Beacon | undefined;
  aoas: AOA[];
  aoaType: 'aoa-ble' | 'aoa-uwb' | 'none';
  beacons: Beacon[];
};

const initialState: MyDeviceListState = {
  projectSelected: [],
  dataDeviceSelected: [],
  deviceSelected: 'dasloop',
  projectWillAssign: {
    id: '',
    name: '',
  },
  isSuccessToAssigned: false,
  dastracks: [],
  dasloops: [],
  daspowers: [],
  cctvItem: undefined,
  isSuccessToEdit: false,
  aoaItem: undefined,
  aoaUwbItem: undefined,
  aoaTagItem: undefined,
  dastrackVItem: null,
  dasairItem: undefined,
  beaconItem: undefined,
  dastemps: [],
  aoas: [],
  beacons: [],
  aoaType: 'none',
};

export const myDevicePageSlice = createSlice({
  name: 'devicePage',
  initialState,
  reducers: {
    setDataDeviceSelected: (
      state,
      action: PayloadAction<MyDeviceListState['dataDeviceSelected']>,
    ) => {
      return { ...state, dataDeviceSelected: action.payload };
    },
    updateDeviceSelected: (
      state,
      action: PayloadAction<MyDeviceListState['deviceSelected']>,
    ) => {
      return { ...state, deviceSelected: action.payload };
    },
    updateProjectWillAssign: (
      state,
      action: PayloadAction<MyDeviceListState['projectWillAssign']>,
    ) => {
      return { ...state, projectWillAssign: action.payload };
    },
    updateIsSuccessToAssign: (
      state,
      action: PayloadAction<MyDeviceListState['isSuccessToAssigned']>,
    ) => {
      return { ...state, isSuccessToAssigned: action.payload };
    },
    updateIsSuccessToEdit: (
      state,
      action: PayloadAction<MyDeviceListState['isSuccessToEdit']>,
    ) => {
      return { ...state, isSuccessToEdit: action.payload };
    },
    setDastracks: (
      state,
      action: PayloadAction<MyDeviceListState['dastracks']>,
    ) => {
      return { ...state, dastracks: action.payload };
    },
    setDasloops: (
      state,
      action: PayloadAction<MyDeviceListState['dasloops']>,
    ) => {
      return { ...state, dasloops: action.payload };
    },
    setDaspowers: (
      state,
      action: PayloadAction<MyDeviceListState['daspowers']>,
    ) => {
      return { ...state, daspowers: action.payload };
    },
    setDastemps: (
      state,
      action: PayloadAction<MyDeviceListState['dastemps']>,
    ) => {
      return { ...state, dastemps: action.payload };
    },
    setDasairData: (
      state,
      action: PayloadAction<MyDeviceListState['dasairItem']>,
    ) => {
      return { ...state, dasairItem: action.payload };
    },
    setCCTVonProject: (
      state,
      action: PayloadAction<MyDeviceListState['cctvItem']>,
    ) => {
      return { ...state, cctvItem: action.payload };
    },
    setAOAData: (
      state,
      action: PayloadAction<MyDeviceListState['aoaItem']>,
    ) => {
      return { ...state, aoaItem: action.payload };
    },
    setAOAUwbData: (
      state,
      action: PayloadAction<MyDeviceListState['aoaUwbItem']>,
    ) => {
      return { ...state, aoaUwbItem: action.payload };
    },
    setAOATagData: (
      state,
      action: PayloadAction<MyDeviceListState['aoaTagItem']>,
    ) => {
      return { ...state, aoaTagItem: action.payload };
    },
    setDastrackVData: (
      state,
      action: PayloadAction<MyDeviceListState['dastrackVItem']>,
    ) => {
      return { ...state, dastrackVItem: action.payload };
    },
    setAOAs: (state, action: PayloadAction<MyDeviceListState['aoas']>) => {
      return { ...state, aoas: action.payload };
    },
    setBeaconData: (
      state,
      action: PayloadAction<MyDeviceListState['beaconItem']>,
    ) => {
      return { ...state, beaconItem: action.payload };
    },
    setBeacons: (
      state,
      action: PayloadAction<MyDeviceListState['beacons']>,
    ) => {
      return { ...state, beacons: action.payload };
    },
    setAOAType: (
      state,
      action: PayloadAction<MyDeviceListState['aoaType']>,
    ) => {
      return { ...state, aoaType: action.payload };
    },
  },
});

export const {
  setDataDeviceSelected,
  updateDeviceSelected,
  updateProjectWillAssign,
  updateIsSuccessToAssign,
  updateIsSuccessToEdit,
  setDastracks,
  setDasloops,
  setDaspowers,
  setDastemps,
  setCCTVonProject,
  setDasairData,
  setAOAData,
  setAOAType,
  setAOAUwbData,
  setAOATagData,
  setDastrackVData,
  setAOAs,
  setBeaconData,
  setBeacons,
} = myDevicePageSlice.actions;
export default myDevicePageSlice.reducer;
