import { useEffect } from 'react';

import { FloorPlan } from '../../../types';

import { addImageFloorPlan } from '../Map2d/addLayers';

import '../Map2d/index.css';

const useMapboxFloorPlan = (
  isMapReady: boolean,
  floorPlans: FloorPlan[],
  mapLibreRef: maplibregl.Map | null,
  floorPlanSequence?: FloorPlan[],
) => {
  useEffect(() => {
    if (isMapReady && floorPlans.length > 0 && mapLibreRef) {
      const visibleFloorPlanIds = floorPlanSequence
        ? floorPlanSequence.map((plan) => plan.id)
        : [];

      floorPlans.forEach((floorPlan) => {
        const sourceId = `site-image-${floorPlan.id}`;
        const layerId = `site-image-layer-${floorPlan.id}`;

        if (!mapLibreRef || !mapLibreRef.getStyle()) return;

        // Remove floor plans that are not in the sequence
        if (!visibleFloorPlanIds.includes(floorPlan.id)) {
          const layer = mapLibreRef?.getLayer(layerId);
          if (layer) {
            mapLibreRef.removeLayer(layerId);
          }
          if (mapLibreRef.getSource(sourceId)) {
            mapLibreRef.removeSource(sourceId);
          }
          return;
        }

        // Add or update floor plans in the sequence
        if (floorPlan.imageURL) {
          if (!mapLibreRef?.getSource(sourceId)) {
            addImageFloorPlan(
              mapLibreRef,
              {
                id: floorPlan.id,
                url: floorPlan.imageURL,
                coordinates: floorPlan.rectangle,
                altitude: floorPlan.altitude,
                opacity: floorPlan.transparency,
              },
              'area-layer',
            );
          }
        }
      });
    }
  }, [isMapReady, floorPlans, floorPlanSequence, mapLibreRef]);
};

export default useMapboxFloorPlan;
