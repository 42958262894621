import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { VideoCameraFront } from '@mui/icons-material';

import { FeatureListItem, FeatureType } from '../../types';

import cctvSvg from '../../assets/images/svg/cctv.svg';
import dasairSvg from '../../assets/images/svg/dasair.svg';
import dascasSvg from '../../assets/images/svg/dascasSvg.svg';
import dasconcreteSvg from '../../assets/images/svg/dasconcrete.svg';
import dasGasSvg from '../../assets/images/svg/dasgas.svg';
import dasloopSvg from '../../assets/images/svg/dasloop.svg';
import daspowerSvg from '../../assets/images/svg/daspower.svg';
import dastempSvg from '../../assets/images/svg/dastemp.svg';
import factoryFloor from '../../assets/images/svg/factory-floor.svg';
import aiCameraSvg from '../../assets/images/svg/icon_ai-camera.svg';
import daslockSvg from '../../assets/images/svg/icon_daslock.svg';
import pipeSvg from '../../assets/images/svg/pipe.svg';
import plantSvg from '../../assets/images/svg/plant.svg';
import tunnelSvg from '../../assets/images/svg/tunnel.svg';
import dastrackV from '../../assets/images/svg/vehicle.svg';
import AttendanceSvgIcon from '../../assets/SvgIcon/AttendanceSvgIcon';
import ConfinedSpaceSvgIcon from '../../assets/SvgIcon/ConfinedSpaceSvgIcon';
import SafieSvgIcon from '../../assets/SvgIcon/third-party/SafieSvgIcon';
import IconContainer from '../IconContainer';
import DarkTooltip from '../Toolip/DarkTooltip';

interface BasicMenuProps {
  dataFeatures: { [key in FeatureType]: FeatureListItem } | undefined;
  selectedProjectId: string | null;
  enterDelay: number;
  expand: boolean;
}

const BasicMenu: React.FC<BasicMenuProps> = ({
  dataFeatures,
  selectedProjectId,
  enterDelay,
  expand,
}) => {
  const { t } = useTranslation('sidebar');

  return (
    <>
      {dataFeatures?.dasloop && (
        <NavLink
          to={`/dasloop-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasloop"
        >
          <DarkTooltip
            title={t('tooltip.dasloop-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dasloopSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dasloop-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dastrack && (
        <NavLink
          to={`/dastrack-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dastrack"
        >
          <DarkTooltip
            title={t('tooltip.dastrack-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={plantSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dastrack-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.factory_management && (
        <NavLink to={`/factory-floor-dashboard/${selectedProjectId}`}>
          <DarkTooltip
            title={t('tooltip.factory-floor')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={factoryFloor} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.factory-floor')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dascas && (
        <NavLink
          to={`/dascas-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dascas"
        >
          <DarkTooltip
            title={t('tooltip.dascas-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dascasSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dascas-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.daspower && (
        <NavLink
          to={`/daspower-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-daspower"
        >
          <DarkTooltip
            title={t('tooltip.daspower-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={daspowerSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.daspower-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dastemp && (
        <NavLink
          to={`/dastemp-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dastemp"
        >
          <DarkTooltip
            title={t('tooltip.dastemp-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dastempSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dastemp-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dastrack_v && (
        <NavLink
          to={`/dastrack-v-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dastrack-v"
        >
          <DarkTooltip
            title={t('tooltip.dastrack-v-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dastrackV} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dastrack-v-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dasair && (
        <NavLink
          to={`/dasair-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasair"
        >
          <DarkTooltip
            title={t('tooltip.dasair-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dasairSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dasair-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.tunnel && (
        <NavLink
          to={`/tunnel-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-tunnel"
        >
          <DarkTooltip
            title={t('tooltip.tunnel')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={tunnelSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.tunnel')} </span>}
        </NavLink>
      )}
      {dataFeatures?.attendance && (
        <NavLink
          to={`/multi-storey-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-attendance"
        >
          <DarkTooltip
            title={t('tooltip.multi-storey')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <AttendanceSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.multi-storey')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dasgas && (
        <NavLink
          to={`/dasgas-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasgas"
        >
          <DarkTooltip
            title={t('tooltip.dasgas-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dasGasSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.dasgas-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.daswater && (
        <NavLink
          to={`/daswater-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasair"
        >
          <DarkTooltip
            title={t('tooltip.daswater-overview')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={pipeSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.daswater-overview')} </span>}
        </NavLink>
      )}
      {dataFeatures?.cctv && (
        <NavLink
          to={`/cctv-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-cctv"
        >
          <DarkTooltip
            title={t('tooltip.cctv')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={cctvSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.cctv')} </span>}
        </NavLink>
      )}
      {(selectedProjectId === 'ff222130-8d6a-11ed-92b3-bba15f6c742a' ||
        selectedProjectId === '3ab4e2a0-0729-11ed-a223-6dabad045fcf') && (
        <NavLink
          to={`/dascart-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-cctv"
        >
          {/* not translayion */}
          <DarkTooltip
            title={t('tooltip.inspection-vehicle')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <VideoCameraFront />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.inspection-vehicle')}</span>}
        </NavLink>
      )}
      {dataFeatures?.daslock && (
        <NavLink
          to={`/daslock-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-daslock"
        >
          <DarkTooltip
            title={t('tooltip.daslock')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={daslockSvg} />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.daslock')} </span>}
        </NavLink>
      )}
      {dataFeatures?.dasconcrete && (
        <NavLink
          to={`/concrete-sensor-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-concrete-sensor"
        >
          <DarkTooltip
            title={t('tooltip.concrete-sensor')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dasconcreteSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.concrete-sensor')} </span>}
        </NavLink>
      )}
      {dataFeatures?.confined && (
        <NavLink
          to={`/confined-space-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasloop"
        >
          <DarkTooltip
            title={t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <ConfinedSpaceSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>{t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}</span>
          )}
        </NavLink>
      )}
      {dataFeatures?.das_ai_box && (
        <NavLink
          to={`/ai-camera/${selectedProjectId}`}
          data-cy="nav-sidebar-ai-camera"
        >
          <DarkTooltip
            title={t('ai-camera')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={aiCameraSvg} />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('ai-camera')} </span>}
        </NavLink>
      )}
      {dataFeatures?.safie && (
        <NavLink
          to={`/safie-camera/${selectedProjectId}`}
          data-cy="nav-sidebar-safie-camera"
        >
          <DarkTooltip
            title={'Safie Camera'}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <SafieSvgIcon viewBox="0 0 52 52" width={23} height={26} />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>Safie Camera</span>}
        </NavLink>
      )}
    </>
  );
};

export default BasicMenu;
