import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery } from '@tanstack/react-query';
import { enUS, ja, zhTW } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';

import './i18n';

import { NotificationMetaData, User } from './types';

import { getOrgs } from './apis/OrgApi';
import { getMe } from './apis/UserApi';
import { useAppDispatch, useAppSelector } from './hooks';
import useWebsocket from './hooks/useWebSocket';
import { setOrgMap } from './slices/systemSlice';
import { setUser } from './slices/userSlice';

import Loading from './components/Loading';
import Main from './components/Main';

import { PubSubContext } from './utils/PubSub';

const NEED_PROJECT_PAGES = [
  'dashboard',
  'project-setting',
  'dasloop-dashboard',
  'dastrack-dashboard',
  'dastemp-dashboard',
  'daspower-dashboard',
  'dastemp-dashboard',
  'dasair-dashboard',
];

const needProjectRegExp = new RegExp(
  NEED_PROJECT_PAGES.reduce((prev, curr, index) => {
    if (index === 0) return `${prev}${curr}`;
    return `${prev}|${curr}`;
  }, '^/(?<page>(') + '))$',
);

const Container = styled('div')`
  display: flex;
`;

const MainContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const App: React.FC = () => {
  const pubSub = useContext(PubSubContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((store) => store.auth.accessToken);
  useWebsocket(accessToken, pubSub);
  const userId = useAppSelector((store) => store.user.id);
  const featureMap = useAppSelector((store) => store.system.featureListMap);
  const selectedProjectId = useAppSelector(
    (store) => store.system.selectedProjectId,
  );

  const passwordChangeRequired = useAppSelector(
    (store) => store.user.passwordChangeRequired,
  );
  const { data: user, isSuccess: isUserSuccess } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      return getMe().then((res) => res.data.data);
    },
    enabled: !!accessToken,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isUserSuccess && user) {
      dispatch(setUser(user));
    }
  }, [isUserSuccess, user]);

  const notificationMetaData: NotificationMetaData = {
    alertAmount: featureMap.notification?.metadata?.alertAmount,
    alertDuration: featureMap.notification?.metadata?.alertDuration,
  };

  const { data: orgData, isSuccess: isSucessOrgData } = useQuery({
    queryKey: ['get-orgs', user?.id, user?.org.country],
    queryFn: async () => {
      const res = await getOrgs({ country: (user as User).org.country }).then(
        (resOrg) => resOrg.data.data,
      );
      const res2 = await getOrgs({ country: 'N/A' }).then(
        (resOrg2) => resOrg2.data.data,
      );
      const allOrg = res.concat(res2);
      return allOrg;
    },
    enabled: !!accessToken && !!user,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSucessOrgData && orgData) {
      dispatch(setOrgMap(orgData));
    }
  }, [isSucessOrgData, orgData]);

  useEffect(() => {
    const redirectUrl = sessionStorage.getItem('redirectUrl');

    if (!accessToken) {
      switch (location.pathname) {
        case '/login':
        case '/register':
        case '/alert-reaction-form':
          return;
        case '/device/information':
          if (location.search !== '') {
            sessionStorage.setItem(
              'redirectUrl',
              `${location.pathname}${location.search}`,
            );
          }
          break;
        case '/resource/information':
          if (location.search !== '') {
            sessionStorage.setItem(
              'redirectUrl',
              `${location.pathname}${location.search}`,
            );
          }
          break;
      }
      navigate('/login', { replace: true });
      return;
    }

    if (passwordChangeRequired) {
      if (location.pathname !== '/login') {
        navigate('/login#change-new-password', { replace: true });
        return undefined;
      }
    }

    if (redirectUrl) {
      sessionStorage.removeItem('redirectUrl');
      navigate(redirectUrl, { replace: true });
      return;
    }

    if (!selectedProjectId) {
      if (location.pathname === '/device/information') {
        return;
      }
      if (location.pathname === '/resource/information') {
        return;
      }

      if (location.pathname.indexOf('/project') === -1) {
        navigate('/project', { replace: true });
        return;
      }
    } else if (location.pathname === '/') {
      navigate(`/dashboard/${selectedProjectId}`, { replace: true });
    } else {
      const result = needProjectRegExp.exec(location.pathname);
      if (result?.groups?.page) {
        navigate(`/${result.groups.page}/${selectedProjectId}`, {
          replace: true,
        });
      }
    }
  }, [
    accessToken,
    passwordChangeRequired,
    selectedProjectId,
    location.pathname,
  ]);

  let dateLocale;
  switch (i18n.language) {
    case 'zh-Hant':
      dateLocale = zhTW;
      break;
    case 'ja':
      dateLocale = ja;
      break;
    default:
      dateLocale = enUS;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateLocale}
    >
      <SnackbarProvider
        maxSnack={notificationMetaData?.alertAmount ?? 10}
        autoHideDuration={notificationMetaData?.alertDuration || 20000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Container>
          <MainContainer>
            <Main>{!userId || userId === '' ? <Loading /> : <Outlet />}</Main>
          </MainContainer>
        </Container>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default App;
