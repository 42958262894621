import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Dasloop } from '../../types/Device';

type DeviceSliceState = {
  dasloops: Dasloop[];
};

const initialState: DeviceSliceState = {
  dasloops: [],
};

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDasloops: (
      state,
      action: PayloadAction<DeviceSliceState['dasloops']>,
    ) => {
      return { ...state, dasloops: action.payload };
    },
    resetAll: () => initialState,
  },
});

export const { setDasloops, resetAll } = deviceSlice.actions;
export default deviceSlice.reducer;
