import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasloopLiveCallSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.802 18.8556C33.8368 19.5203 33.8254 20.1866 33.7681 20.8496C33.7681 20.8496 33.836 22.2062 30.8843 24.604C26.1278 28.4634 19.5666 28.7316 15.2383 28.267C15.2546 24.8625 15.7506 14.6615 20.9623 10.874C26.965 6.51124 31.2906 11.1865 31.3789 11.2889C33.2503 13.7516 33.7164 16.863 33.802 18.8556Z"
        fill="#FFB905"
      />
      <path
        d="M15.2377 28.2671C15.2377 28.534 15.2377 28.7594 15.2377 28.9364C14.7077 28.8811 14.232 28.8175 13.823 28.7525C13.4139 28.6875 13.0675 28.6267 12.7984 28.5741C11.9076 28.4099 11.0385 28.1417 10.2081 27.7748C10.2081 27.6366 10.1986 27.3849 10.1973 27.0226V26.9355C10.1973 23.7467 10.6145 13.3023 15.9227 9.4664C17.3455 8.44035 18.6868 7.90521 19.8855 7.70747C20.7958 7.56734 21.7153 7.498 22.636 7.50004C27.0582 7.50004 29.7463 9.14698 31.3716 11.289C31.2806 11.188 26.9576 6.51272 20.9549 10.8741C15.7555 14.6616 15.254 24.8626 15.2377 28.2671Z"
        fill="#FFCE4F"
      />
      <path
        d="M19.8884 7.70752C18.6898 7.90526 17.3484 8.44041 15.9256 9.46645C10.6174 13.3024 10.2002 23.7468 10.2002 26.9355V27.0226H10.1839C8.78139 26.2967 8.57617 25.5375 8.57617 25.5375C8.59384 24.7465 8.62374 23.7398 8.71207 22.6032C9.16054 17.2572 11.0482 9.10693 19.8884 7.70752Z"
        fill="#E6A600"
      />
      <path
        d="M31.6326 25.5596C29.4202 27.3573 24.5414 30.1879 16.2584 29.5864C16.2003 29.6788 16.1309 29.7634 16.0519 29.838C15.8293 30.0454 15.5382 30.1599 15.2365 30.1589C15.1957 30.1589 15.1549 30.1588 15.1128 30.1519C14.61 30.0994 14.114 30.0344 13.6397 29.9611C13.2659 29.903 12.9044 29.8408 12.5674 29.773C11.5858 29.591 10.6281 29.294 9.71354 28.888C9.50848 28.7939 9.33356 28.643 9.20883 28.4525C9.0841 28.262 9.01461 28.0397 9.00823 27.8108C9.00823 27.7929 9.00823 27.7707 9.00823 27.7472C7.79466 26.9452 7.48481 26.1224 7.41415 25.8624C7.38275 25.7477 7.36806 25.6289 7.37062 25.5098C7.37877 25.1724 7.38832 24.806 7.40327 24.416C6.06738 25.7587 5.16365 27.1153 5.01688 28.3239C4.45834 32.9397 17.8824 36.2308 27.326 30.2916C31.2793 27.8025 35.537 24.0012 34.9445 21.165C34.8303 21.9007 34.2459 23.437 31.6326 25.5596Z"
        fill="#FFB905"
      />
      <path
        d="M6.25 28.3335C9.88251 30.0255 15.398 31.2176 17.9167 31.6668C18.3733 26.5449 19.3569 22.6838 19.5833 20.8335L7.91667 18.3335C6.25 22.5002 6.26841 26.9158 6.25 28.3335Z"
        fill="#878787"
      />
      <path
        d="M11.8432 28.6142C14.0471 28.6142 15.8403 26.821 15.8403 24.6172C15.8403 22.4134 14.0471 20.6201 11.8432 20.6201C9.63943 20.6201 7.84619 22.4134 7.84619 24.6172C7.84803 26.821 9.64128 28.6142 11.8432 28.6142Z"
        fill="#C4C4C4"
      />
      <path
        d="M11.8433 27.4834C13.423 27.4834 14.7081 26.1984 14.7081 24.6187C14.7081 23.039 13.423 21.7539 11.8433 21.7539C10.2636 21.7539 8.97852 23.039 8.97852 24.6187C8.97852 26.1984 10.2655 27.4834 11.8433 27.4834Z"
        fill="#76EFFF"
      />
      <path
        d="M21.2498 20L19.5832 20.8333C18.2498 22.8333 17.9165 28.8889 17.9165 31.6667L19.5832 30.8333L21.2498 20Z"
        fill="#333333"
      />
      <path
        d="M7.66683 14L6.3335 14.6667L15.6668 16.6667L17.0002 16L7.66683 14Z"
        fill="#A1A1A1"
      />
    </SvgIcon>
  );
};

export default DasloopLiveCallSvgColorMedium;
