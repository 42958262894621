import { useContext, useEffect, useRef, useState } from 'react';
import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';

import { DataPoint } from '../../types';
import { DasCTag } from '../../types/Device';
import {
  WebSocketCoordinates,
  WebSocketDasCTagDataPoint,
} from '../../types/Websocket';

import { getProjectDasCTags } from '../../apis/DasCTagApi';

import { PubSubContext } from '../../utils/PubSub';

type UseProjectDasCTagMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  indexBy?: 'id' | 'dasId';
  orderBy?: 'das_id' | 'last_data_point_received_at';
  queryOptions?: {
    enabled?: boolean;
  };
  onProgress?: (progress) => void;
};

type DasCTagMap = { [id: string]: DasCTag | undefined };

export const useProjectDasCTagMapWithPages = ({
  projectId,
  indexBy = 'id',
  orderBy,
  queryKey,
  queryOptions,
  onProgress,
}: UseProjectDasCTagMapParams) => {
  const dataPointQueueRef = useRef<WebSocketDasCTagDataPoint[]>([]);
  const coordinatesQueueRef = useRef<WebSocketCoordinates[]>([]);
  const pubSub = useContext(PubSubContext);
  const [dasCTagMap, setDasCTagMap] = useState<DasCTagMap>({});
  const [total, setTotal] = useState(0);

  const { data, ...others } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const res = await getProjectDasCTags({
        projectId: projectId as string,
        params: {
          orderBy,
          nextCursor: pageParam,
        },
      });
      setTotal((origin) => {
        if (origin !== res.data.metadata.total) {
          return res.data.metadata.total;
        }

        return origin;
      });
      return res.data;
    },
    getNextPageParam: (res) => res.paging.nextCursor || undefined,
    enabled: !!projectId && (queryOptions?.enabled ?? true),
    refetchOnWindowFocus: false,
    initialPageParam: undefined,
  });

  useEffect(() => {
    if (data) {
      const newDasCTagMap: DasCTagMap = {};
      let count = 0;
      data.pages
        .map((page) => page.data)
        .flat()
        .forEach((dasCTag) => {
          count++;
          if (indexBy === 'dasId') {
            newDasCTagMap[dasCTag.dasId] = dasCTag;
          } else if (indexBy === 'id') {
            newDasCTagMap[dasCTag.id] = dasCTag;
          }
        });
      setDasCTagMap((origin) => ({ ...origin, ...newDasCTagMap }));
      onProgress?.((count / (total == 0 ? 1 : total)) * 100);
    }
  }, [data, indexBy, total]);

  useEffect(() => {
    const dataPointHandler = (datapointData: WebSocketDasCTagDataPoint) => {
      if (projectId === datapointData.endpoint.metadata['dsm/projectId']) {
        dataPointQueueRef.current.push(datapointData);
      }
    };

    const coordinatesHandler = (coordinatesData: WebSocketCoordinates) => {
      if (projectId === coordinatesData.endpoint.metadata['dsm/projectId']) {
        coordinatesQueueRef.current.push(coordinatesData);
      }
    };

    pubSub?.subscribe(
      'das_collision_tag:data-point-received',
      dataPointHandler,
    );
    pubSub?.subscribe('coordinates-updated', coordinatesHandler);
    return () => {
      pubSub?.unsubscribe(
        'das_collision_tag:data-point-received',
        dataPointHandler,
      );
      pubSub?.unsubscribe('coordinates-updated', coordinatesHandler);
    };
  }, [pubSub, projectId, indexBy]);

  useEffect(() => {
    const dataPointQueueTimer = setInterval(() => {
      const dataPoints: WebSocketDasCTagDataPoint[] = [];

      while (dataPointQueueRef.current.length > 0) {
        const newDataPoint =
          dataPointQueueRef.current.shift() as WebSocketDasCTagDataPoint;
        dataPoints.push(newDataPoint);
      }

      if (dataPoints.length > 0) {
        setDasCTagMap((origin) => {
          const newDasCTagMap = { ...origin };
          dataPoints
            .filter((datapointData) => {
              return !!origin[datapointData.endpoint[indexBy]];
            })
            .map((datapointData) => {
              const newDasCTag = origin[datapointData.endpoint[indexBy]];

              if (newDasCTag) {
                const updateDataPoint: { [key: string]: DataPoint<any> } =
                  Object.entries(datapointData.dataPoint).reduce(
                    (prev, [key, value]) => {
                      if (value !== undefined || value !== null) {
                        prev[key] = {
                          timestamp: datapointData.timestamp,
                          value,
                          stale: false,
                        };
                      }
                      return prev;
                    },
                    {},
                  );

                newDasCTag.shadow.dataPoint = {
                  ...newDasCTag.shadow.dataPoint,
                  ...updateDataPoint,
                };

                newDasCTagMap[newDasCTag[indexBy]] = newDasCTag;
              }
            });

          return newDasCTagMap;
        });
      }
    }, 1000);

    const coordinatesQueueTimer = setInterval(() => {
      const coords: WebSocketCoordinates[] = [];

      while (coordinatesQueueRef.current.length > 0) {
        const webSocketCoordinates =
          coordinatesQueueRef.current.shift() as WebSocketCoordinates;
        coords.push(webSocketCoordinates);
      }

      if (coords.length > 0) {
        setDasCTagMap((origin) => {
          const newDasCTagMap = { ...origin };
          coords
            .filter((coord) => {
              return !!origin[coord.endpoint[indexBy]];
            })
            .map((coord) => {
              const newDasCTag = origin[coord.endpoint[indexBy]];

              if (newDasCTag) {
                newDasCTag.coordinates = coord.coordinates;
                newDasCTag.coordinatesSource = coord.source;
                newDasCTag.lastCoordinatesUpdatedAt = coord.timestamp;
                newDasCTagMap[newDasCTag[indexBy]] = newDasCTag;
              }
            });

          return newDasCTagMap;
        });
      }
    }, 1000);

    return () => {
      clearInterval(dataPointQueueTimer);
      clearInterval(coordinatesQueueTimer);
    };
  }, []);

  const moockDasCTag: { [id: string]: DasCTag | undefined } = {
    '0a58223c-9da6-11ef-8917-2b06ac346396': {
      owner: null,
      id: '0a58223c-9da6-11ef-8917-2b06ac346396',
      ownerUserId: '77a7f6ad-1ed5-43f9-a028-83a3462e0e07',
      dasId: 'TUCATPE990005',
      projectId: 'b7074fd0-b181-11ec-9a13-a3f882f1202f',
      workerId: 'b4c7448c-9da6-11ef-b2c0-3b50b5913d45',
      collisionId: '30020E',
      shadow: {
        dataPoint: {
          reportInterval: {
            timestamp: null,
            value: null,
            stale: false,
          },
          timestamp: null,
          shutdown: null,
          batteryLevel: {
            timestamp: null,
            value: null,
            stale: false,
          },
          beacons: {
            timestamp: null,
            value: null,
            stale: false,
          },
        },
      },
      coordinates: {
        lon: 121.51794478360733,
        lat: 25.064394036121286,
        alt: 31.84247312694788,
      },
      coordinatesSource: 'uwb_basic',
      lastCoordinatesUpdatedAt: '2025-01-15T03:28:26.000Z',
      createdAt: '2024-11-08T07:49:53.545Z',
    },
    '0a40bc46-9da6-11ef-8917-3b807a8f3a0a': {
      owner: null,
      id: '0a40bc46-9da6-11ef-8917-3b807a8f3a0a',
      ownerUserId: '77a7f6ad-1ed5-43f9-a028-83a3462e0e07',
      dasId: 'TUCATPE990004',
      projectId: 'b7074fd0-b181-11ec-9a13-a3f882f1202f',
      workerId: 'b4b7b058-9da6-11ef-b2c0-c370f2786068',
      collisionId: 'F90004',
      shadow: {
        dataPoint: {
          reportInterval: {
            timestamp: null,
            value: null,
            stale: false,
          },
          timestamp: null,
          shutdown: null,
          batteryLevel: {
            timestamp: null,
            value: null,
            stale: false,
          },
          beacons: {
            timestamp: null,
            value: null,
            stale: false,
          },
        },
      },
      coordinates: {
        alt: 36.5,
        lon: 121.51814099719992,
        lat: 25.06440043241172,
      },
      coordinatesSource: 'uwb_basic',
      lastCoordinatesUpdatedAt: '2025-01-15T03:28:26.000Z',
      createdAt: '2024-11-08T07:49:53.391Z',
    },
    '0a2aae9c-9da6-11ef-8917-d780562b38c5': {
      owner: null,
      id: '0a2aae9c-9da6-11ef-8917-d780562b38c5',
      ownerUserId: '77a7f6ad-1ed5-43f9-a028-83a3462e0e07',
      dasId: 'TUCATPE990003',
      projectId: 'b7074fd0-b181-11ec-9a13-a3f882f1202f',
      workerId: 'b4a6e91c-9da6-11ef-b2c0-031b81f7e3b7',
      collisionId: 'F90003',
      shadow: {
        dataPoint: {
          reportInterval: {
            timestamp: null,
            value: null,
            stale: false,
          },
          timestamp: null,
          shutdown: null,
          batteryLevel: {
            timestamp: null,
            value: null,
            stale: false,
          },
          beacons: {
            timestamp: null,
            value: null,
            stale: false,
          },
        },
      },
      coordinates: {
        alt: 36.5,
        lon: 121.51814099719992,
        lat: 25.06440043241172,
      },
      coordinatesSource: 'uwb_basic',
      lastCoordinatesUpdatedAt: '2025-01-15T03:28:26.000Z',
      createdAt: '2024-11-08T07:49:53.247Z',
    },
    '0a043cda-9da6-11ef-8917-e79c7efe1f62': {
      id: '0a043cda-9da6-11ef-8917-e79c7efe1f62',
      owner: null,
      ownerUserId: '77a7f6ad-1ed5-43f9-a028-83a3462e0e07',
      dasId: 'TUCATPE990002',
      projectId: 'b7074fd0-b181-11ec-9a13-a3f882f1202f',
      workerId: 'b4977b62-9da6-11ef-b2c0-d7b5aac376d1',
      collisionId: 'F90002',
      shadow: {
        dataPoint: {
          reportInterval: {
            timestamp: null,
            value: null,
            stale: false,
          },
          timestamp: null,
          shutdown: null,
          batteryLevel: {
            timestamp: null,
            value: null,
            stale: false,
          },
          beacons: {
            timestamp: null,
            value: null,
            stale: false,
          },
        },
      },
      coordinates: {
        alt: 36.5,
        lon: 121.51814099719992,
        lat: 25.06440043241172,
      },
      coordinatesSource: 'uwb_basic',
      lastCoordinatesUpdatedAt: '2025-01-15T03:28:26.000Z',
      createdAt: '2024-11-08T07:49:52.995Z',
    },
    '09df3c3c-9da6-11ef-8917-7f3152388689': {
      id: '09df3c3c-9da6-11ef-8917-7f3152388689',
      ownerUserId: '77a7f6ad-1ed5-43f9-a028-83a3462e0e07',
      dasId: 'TUCATPE990001',
      projectId: 'b7074fd0-b181-11ec-9a13-a3f882f1202f',
      workerId: '7d858fc4-5112-11ee-a564-b3ea4d53dbdf',
      collisionId: 'F90001',
      owner: null,
      shadow: {
        dataPoint: {
          reportInterval: {
            timestamp: null,
            value: null,
            stale: false,
          },
          timestamp: null,
          shutdown: null,
          batteryLevel: {
            timestamp: null,
            value: null,
            stale: false,
          },
          beacons: {
            timestamp: null,
            value: null,
            stale: false,
          },
        },
      },
      coordinates: {
        alt: 36.5,
        lon: 121.51814099719992,
        lat: 25.06440043241172,
      },
      coordinatesSource: 'uwb_basic',
      lastCoordinatesUpdatedAt: '2025-01-15T03:28:26.000Z',
      createdAt: '2024-11-08T07:49:52.749Z',
    },
  };

  useEffect(() => {}, [moockDasCTag]);

  return {
    data: dasCTagMap,
    ...others,
  };
};
