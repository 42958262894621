import { isSameDay } from 'date-fns';

import {
  DasIdWillAssign,
  WorkerCertificate,
  WorkerCertificateWillAssign,
  WorkerWillAssign,
} from '../../../types';
import { Dasloop } from '../../../types/Device';
import { Worker } from '../../../types/Resource';

export const checkIsFormWorkerEdited = (
  workerSelected: Worker,
  workerWillAssign: WorkerWillAssign,
) => {
  const checkFieldValue = (field: keyof Worker) => {
    const selectedValue = workerSelected[field];
    const willAssignValue = workerWillAssign[field];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue !== willAssignValue) return true;
    }
    return false;
  };

  if (checkFieldValue('orgId')) return true;
  if (checkFieldValue('name')) return true;
  if (checkFieldValue('gender')) return true;
  if (checkFieldValue('birthday')) return true;
  if (checkFieldValue('registrationNumber')) return true;
  if (checkFieldValue('email')) return true;
  if (checkFieldValue('phone')) return true;
  if (checkFieldValue('trade')) return true;
  if (checkFieldValue('division')) return true;
  if (checkFieldValue('remark')) return true;
  if (checkFieldValue('subContractorName')) return true;
  return false;
};

export const checkDeviceConnected = (
  connectedDevice: Array<Dasloop>,
  dasIDSelected: string,
) => {
  const n = connectedDevice.length;
  if (n !== 0) {
    if (connectedDevice[0].dasId !== dasIDSelected) {
      return false;
    }
  } else {
    if (dasIDSelected) {
      return false;
    }
  }
  return true;
};

export const checkIsArrayAvatarUpdated = (
  workerCertificateWillAssign: Array<WorkerCertificateWillAssign>,
) => {
  if (workerCertificateWillAssign.length > 0) {
    if (workerCertificateWillAssign.some((worker) => worker.imageFile)) {
      return true;
    }
  }
  return false;
};

export const checkisCertificateEdited = (
  workerCertificate: Array<WorkerCertificate>,
  workerCertificateWillAssign: Array<WorkerCertificateWillAssign>,
) => {
  const n = workerCertificate.length;
  const m = workerCertificateWillAssign.length;
  if (n !== m) {
    return true;
  } else {
    for (let i = 0; i < n; i += 1) {
      if (workerCertificate[i].name === null) {
        if (workerCertificateWillAssign[i].name) return true;
      } else {
        if (workerCertificate[i].name !== workerCertificateWillAssign[i].name)
          return true;
      }

      if (workerCertificate[i].number === null) {
        if (workerCertificateWillAssign[i].number) return true;
      } else {
        if (
          workerCertificate[i].number !== workerCertificateWillAssign[i].number
        )
          return true;
      }
      if (workerCertificate[i].expiryDate === null) {
        if (workerCertificateWillAssign[i].expiryDate) return true;
      } else {
        if (
          !isSameDay(
            new Date(workerCertificate[i].expiryDate),
            new Date(workerCertificateWillAssign[i].expiryDate),
          )
        )
          return true;
      }
    }
  }

  return false;
};

export const checkWorkerBasicInformationAvailable = (
  basicInfomation: WorkerWillAssign | undefined,
) => {
  if (!basicInfomation?.orgId) return false;
  if (!basicInfomation?.name) return false;
  if (!basicInfomation?.gender) return false;
  if (!basicInfomation?.birthday) return false;
  return true;
};

export const checkWorkerContactAvailable = (workerContact: string[]) => {
  if (workerContact.length === 0) return false;
  return true;
};

export const checkWorkerCertificate = (
  workerCertificate: WorkerCertificateWillAssign[],
) => {
  if (workerCertificate.length === 0) return false;
  if (
    !workerCertificate.every((item) => {
      if (!item.name) return false;
      if (!item.number) return false;
      if (!item.expiryDate) return false;

      return true;
    })
  )
    return false;

  return true;
};

export const checkWorkerDasloopConnected = (dasIds: DasIdWillAssign[]) => {
  if (dasIds.length === 0) return false;
  return dasIds.reduce((prev, curr) => {
    if (curr.name) return prev && true;
    return false;
  }, true);
};
