import { DascasType, DeviceType } from '../types';

export const handleTextDeviceType = (
  deviceType: DeviceType | DascasType | undefined,
) => {
  switch (deviceType) {
    case 'dasloop':
      return 'DasLoop';
    case 'dasgas':
      return 'DasGas';
    case 'dastrack':
      return 'DasTrack';
    case 'dascas':
      return 'DasCAS';
    case 'dascas_g':
      return 'Main Detector';
    case 'dascas_tag':
      return 'Hook Tag';
    case 'dascas_alarm':
      return 'CabinAlarm';
    case 'dastrack-v':
      return 'DasTrack-V';
    case 'daspower':
      return 'DasPower';
    case 'dastemp':
      return 'DasTemp';
    case 'dasair':
      return 'DasAir';
    case 'daswater':
      return 'DasWater-L';
    case 'beacon':
      return 'DasBeacon';
    case 'aoa-ble':
      return 'DasAoA';
    case 'aoa-uwb':
      return 'DasAoA';
    case 'aoa-tag':
      return 'AoAtag';
    case 'daslock':
      return 'DasLock';
    case 'dasconcrete':
      return 'DasConcrete';
    case 'das_ai_box':
      return 'DasAiBox';
    case 'das_collision_tag':
      return 'DasCTag';
    default:
      return 'Device';
  }
};
