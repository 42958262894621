import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Track, TrackNum, VehicleTrack, VehicleTrackNum } from '../../types';

type State = {
  tracks: Array<TrackNum>;
  tracksFactoryFloor: Array<TrackNum>;
  vehicleTracks: Array<VehicleTrackNum>;
  maxDisplayDate: number;
  indexSlide: number;
  indexRange: number[];
  indexRangeFactoryFloor: number[];
};

const initialState: State = {
  tracks: [],
  vehicleTracks: [],
  tracksFactoryFloor: [],
  maxDisplayDate: Number.POSITIVE_INFINITY,
  indexSlide: Number.POSITIVE_INFINITY,
  indexRange: [0, 0],
  indexRangeFactoryFloor: [0, 0],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setTracks: (state, action: PayloadAction<Track[]>) => {
      return {
        ...state,
        tracks: action.payload.map((track) => ({
          ...track,
          timestamp: new Date(track.timestamp).getTime(),
        })),
      };
    },
    setTracksFactoryFloor: (state, action: PayloadAction<Track[]>) => {
      return {
        ...state,
        tracksFactoryFloor: action.payload.map((track) => ({
          ...track,
          timestamp: new Date(track.timestamp).getTime(),
        })),
      };
    },
    setVehicleTracks: (state, action: PayloadAction<VehicleTrack[]>) => {
      return {
        ...state,
        vehicleTracks: action.payload.map((track) => ({
          ...track,
          timestamp: new Date(track.timestamp).getTime(),
        })),
      };
    },

    setMaxDisplayDate: (state, action: PayloadAction<number | undefined>) => {
      return {
        ...state,
        maxDisplayDate: action.payload
          ? action.payload
          : Number.POSITIVE_INFINITY,
      };
    },
    setIndexSlide: (state, action: PayloadAction<number>) => {
      return { ...state, indexSlide: action.payload };
    },
    setIndexSlideRange: (state, action: PayloadAction<number[]>) => {
      return { ...state, indexRange: action.payload };
    },
    setIndexSlideRangeFactoryFloor: (
      state,
      action: PayloadAction<number[]>,
    ) => {
      return { ...state, indexRangeFactoryFloor: action.payload };
    },
  },
});

export const {
  setTracks,
  setTracksFactoryFloor,
  setVehicleTracks,
  setMaxDisplayDate,
  setIndexSlide,
  setIndexSlideRange,
  setIndexSlideRangeFactoryFloor,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
