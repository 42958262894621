import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const FactoryDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "factory-floor-dashboard" */ '../../pages/factory-floor-dashboard'
    ),
);

const factoryFloorDashboardRouter = (
  <Route path="factory-floor-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <FactoryDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default factoryFloorDashboardRouter;
